import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
    IAlbumsResponse,
    IEditAlbumResponse,
    IEditPlaylistResponse,
    IMeResponse,
    IPlaylistsResponse,
    IPublicSingleAlbumResponse,
    IPublicSinglePlaylistResponse,
    IPublicSingleTrackResponse,
    IStatsResponse
} from '../interfaces/ApiResponses';
import {IAlbum, IPlaylist, ITrack} from '../interfaces/PlayerModels';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * API calls
 */

class Api {
    /**
     * CORE API CALLS
     */

    // Make a GET request.
    private static async get(url: string): Promise<any> {
        try {
            const res = await axios.get(url, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a POST request.
    private static async post(url: string, data: any): Promise<any> {
        try {
            const res = await axios.post(url, data, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a PUT request.
    private static async put(url: string, data: any): Promise<any> {
        try {
            const res = await axios.put(url, data, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Make a DELETE request.
    private static async delete(url: string): Promise<any> {
        try {
            const res = await axios.delete(url, {withCredentials: true});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    // Get the auth status of the user. If this returns HTTP 400, the user is not logged in.
    public static async me(): Promise<IMeResponse> {
        return await this.get(API_URL + '/oauth/me');
    }

    // Get admin stats
    public static async stats(): Promise<IStatsResponse> {
        return await this.get(API_URL + '/admin/stats');
    }

    // Get playlists (including non-public)
    public static async getPlaylists(): Promise<IPlaylistsResponse> {
        return await this.get(API_URL + '/admin/playlists');
    }

    // Get playlist by ID
    public static async getPlaylistById(playlistId: string) {
        return await this.get(API_URL + '/admin/playlists/' + playlistId);
    }

    // Get albums
    public static async getAlbums(): Promise<IAlbumsResponse> {
        return await this.get(API_URL + '/admin/albums');
    }

    // Edit an album
    public static async editAlbum(albumId: string, data: Partial<IAlbum>): Promise<IEditAlbumResponse> {
        return await this.put(API_URL + '/admin/album/' + albumId, data);
    }

    // Create an album
    public static async createAlbum(data: Partial<IAlbum>): Promise<IEditAlbumResponse> {
        return await this.post(API_URL + '/admin/album/new', data);
    }

    // Delete an album
    public static async deleteAlbum(albumId: string): Promise<{ data: boolean }> {
        return await this.delete(API_URL + '/admin/album/' + albumId);
    }

    // Create a playlist
    public static async createPlaylist(data: Partial<IPlaylist>): Promise<IEditPlaylistResponse> {
        return await this.post(API_URL + '/admin/playlist/new', data);
    }

    // Edit a playlist
    public static async editPlaylist(playlistId: string, data: Partial<IPlaylist>): Promise<IEditPlaylistResponse> {
        return await this.put(API_URL + '/admin/playlist/' + playlistId, data);
    }

    // Delete a playlist
    public static async deletePlaylist(playlistId: string): Promise<{ data: boolean }> {
        return await this.delete(API_URL + '/admin/playlist/' + playlistId);
    }

    // Add Track to Playlist
    public static async addPlaylistTrack(playlistId: string, trackId: string, data: Partial<IPlaylist>): Promise<IEditPlaylistResponse> {
        return await this.put(API_URL + '/admin/playlist/' + playlistId + '/addTrack/' + trackId, data);
    }

    // Remove Track from Playlist
    public static async removePlaylistTrack(playlistId: string, trackIndex: string, data: Partial<IPlaylist>): Promise<IEditPlaylistResponse> {
        return await this.put(API_URL + '/admin/playlist/' + playlistId + '/removetrack/' + trackIndex, data);
    }

    // Create Track
    public static async createTrack(data: Partial<ITrack>): Promise<IPublicSingleTrackResponse> {
        return await this.post(API_URL + '/admin/track/new', data);
    }

    // Edit Track
    public static async editTrack(trackId: string, data: Partial<ITrack>): Promise<IPublicSingleTrackResponse> {
        return await this.put(API_URL + '/admin/track/' + trackId, data);
    }

    // Delete Track
    public static async deleteTrack(trackId: string): Promise<IPublicSingleTrackResponse> {
        return await this.delete(API_URL + '/admin/track/' + trackId);
    }

    // Get Album ID
    public static async getAlbumId(albumId?: string): Promise<IPublicSingleAlbumResponse> {
        return await this.get(API_URL + '/public/albums/' + albumId)
    }

    public static async getPlaylistId(playlistid?: string): Promise<IPublicSinglePlaylistResponse> {
        return await this.get(API_URL + '/public/playlists/' + playlistid)
    }

    // Get All Tracks
    public static async getAllTracks(): Promise<IPublicSingleTrackResponse> {
        return await this.get(API_URL + '/public/tracks/')
    }
}

/**
 * React hooks for the API calls, where necessary
 */

// Auth call.
export function useMe() {
    return useQuery<IMeResponse, Error>('auth', () => Api.me(), {retry: 1, staleTime: 1000 * 60 * 10});
}

// Stats call.
export function useStats() {
    return useQuery<IStatsResponse, Error>('stats', () => Api.stats(), {retry: 1, staleTime: 1000 * 60 * 10});
}

// Albums call.
export function useAlbums() {
    return useQuery<IAlbumsResponse, Error>('albums', () => Api.getAlbums(), {retry: 1});
}

// Playlists call.
export function usePlaylists() {
    return useQuery<IPlaylistsResponse, Error>('playlists', () => Api.getPlaylists(), {retry: 1});
}

// Individual playlist call.
export function usePlaylistById(playlistId: string) {
    return useQuery<IPublicSinglePlaylistResponse, Error>(['playlistById', playlistId], () => Api.getPlaylistById(playlistId), {retry: 1});
}

// All Tracks call.
export function useAllTracks() {
    return useQuery<IPublicSingleTrackResponse, Error>(['tracks'], () => Api.getAllTracks(), {retry: 1});
}

// Edit album call.
export function useEditAlbum() {
    const qs = useQueryClient();

    return useMutation<IEditAlbumResponse, Error, {
        albumId: string,
        data: Partial<IAlbum>
    }>('editAlbum', async (data) => {
        return await Api.editAlbum(data.albumId, data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('albums');
        }
    });
}

// Create album call.
export function useCreateAlbum() {
    const qs = useQueryClient();

    return useMutation<IEditAlbumResponse, Error, { data: Partial<IAlbum> }>('createAlbum', async (data) => {
        return await Api.createAlbum(data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('albums');
        }
    });
}

// Delete album call.
export function useDeleteAlbum() {
    const qs = useQueryClient();

    return useMutation<{ data: boolean }, Error, { albumId: string }>('deleteAlbum', async (data) => {
        return await Api.deleteAlbum(data.albumId);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('albums');
        }
    });
}

// Create playlist call.
export function useCreatePlaylist() {
    const qs = useQueryClient();

    return useMutation<IEditPlaylistResponse, Error, { data: Partial<IPlaylist> }>('createPlaylist', async (data) => {
        return await Api.createPlaylist(data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('playlists');
        }
    });
}

// Edit playlist call.
export function useEditPlaylist() {
    const qs = useQueryClient();

    return useMutation<IEditPlaylistResponse, Error, {
        playlistId: string,
        data: Partial<IPlaylist>
    }>('editPlaylist', async (data) => {
        return await Api.editPlaylist(data.playlistId, data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('playlists');
        }
    });
}

// Delete playlists call.
export function useDeletePlaylist() {
    const qs = useQueryClient();

    return useMutation<{ data: boolean }, Error, { playlistId: string }>('deletePlaylist', async (data) => {
        return await Api.deletePlaylist(data.playlistId);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('playlists');
        }
    });
}

// Add Playlist Track Call
export function useAddPlaylistTrack() {
    const qs = useQueryClient();

    return useMutation<IEditPlaylistResponse, Error, {
        playlistId: string,
        trackId: string,
        data: Partial<IPlaylist>
    }>('addPlaylistTrack', async (data) => {
        return await Api.addPlaylistTrack(data.playlistId, data.trackId, data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('playlists');
        }
    });
}

// Remove Playlist Track call
export function useRemovePlaylistTrack() {
    const qs = useQueryClient();

    return useMutation<IEditPlaylistResponse, Error, {
        playlistId: string,
        trackIndex: string,
        data: Partial<IPlaylist>
    }>('removePlaylistTrack', async (data) => {
        return await Api.removePlaylistTrack(data.playlistId, data.trackIndex, data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('playlists');
        }
    });
}

// Create track call.
export function useCreateTrack() {
    const qs = useQueryClient();

    return useMutation<IPublicSingleTrackResponse, Error, { data: Partial<ITrack> }>('createTrack', async (data) => {
        return await Api.createTrack(data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('tracks');
        }
    });
}

// Edit Track Call
export function useEditTrack() {
    const qs = useQueryClient();

    return useMutation<IPublicSingleTrackResponse, Error, {
        trackId: string,
        data: Partial<ITrack>
    }>('editTrack', async (data) => {
        return await Api.editTrack(data.trackId, data.data);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('tracks');
        }
    });
}

// Delete Track Call
export function useDeleteTrack() {
    const qs = useQueryClient();

    return useMutation<IPublicSingleTrackResponse, Error, { trackId: string }>('createTrack', async (data) => {
        return await Api.deleteTrack(data.trackId);
    }, {
        onSuccess: () => {
            // If we're successful, invalidate the albums call.
            qs.invalidateQueries('tracks');
        }
    });
}

export default Api;