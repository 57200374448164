import {useAlbums} from '../utils/api';
import AlbumsTable from '@components/AlbumsTable';
import CreateAlbumModal from '@components/modals/CreateAlbumModal';

const ManageAlbums = () => {
  const {status} = useAlbums();

  return <div className={'mt-4'}>
    <h3>Manage Albums</h3>
    <p>
      Add, edit, and remove albums (and their tracks) from the database.
    </p>
    <CreateAlbumModal />
    <div className={'mt-4'}>
      {status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : <AlbumsTable />}
    </div>
  </div>
}

export default ManageAlbums;