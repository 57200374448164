import {ITrack} from '../interfaces/PlayerModels';
import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, ButtonGroup} from "react-bootstrap";
import {useDeleteTrack, useRemovePlaylistTrack} from "../utils/api";
import ConfirmModal from "@components/modals/ConfirmModal";
import EditTrackModal from "@components/modals/EditTrackModal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useLoaderData} from "react-router-dom";
import {IPublicSinglePlaylistResponse} from "../interfaces/ApiResponses";

interface IProps {
    tracks: ITrack[];
    album?: string;
    type?: string;
}

const ListTable: React.FC<IProps> = observer((props) => {
    const {tracks} = props;
    const deleteTrack = useDeleteTrack();
    const removeTrack = useRemovePlaylistTrack();
    const [itemToDelete, setItemToDelete] = useState('');
    const [itemToRemove, setItemToRemove] = useState('');
    const playlist = useLoaderData() as IPublicSinglePlaylistResponse;

    const doDelete = async () => {
        const i = itemToDelete;
        setItemToDelete(''); // Clear the item we want to delete, so we can close the modal
        await deleteTrack.mutateAsync({trackId: i});

        window.location.reload();
    };

    const doRemove = async () => {
        const i = itemToRemove;
        setItemToRemove(''); // Clear the item we want to remove, so we can close the modal
        await removeTrack.mutateAsync({playlistId: playlist.data.id, trackIndex: i, data: playlist.data});

        window.location.reload();
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        display: isDragging ? "table" : "table-row",
        opacity: isDragging ? 1 : 1,
        boxShadow: isDragging ? "0 0 5px rgba(0,0,0,0.2)" : "none",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    // Drag and Drop Setup
    const [trackitems, setTrackItems] = useState(tracks);

    //Reorder the result
    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Update view on drag end
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            trackitems,
            result.source.index,
            result.destination.index
        );

        setTrackItems(items);
    }


    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided) => (
                        <table
                            className="table table-tracks table-striped w-100"
                            ref={provided.innerRef}
                        >
                            <thead className="table-head">
                            <tr>
                                <td className="move"></td>
                                <td className="song"></td>
                                <td className="artist"></td>
                                <td className="actions"></td>
                            </tr>
                            <tr>
                                <th className="move"></th>
                                <th className="song">
                                    <strong>
                                        Song
                                    </strong>
                                </th>
                                <th className="artist">
                                    <strong>
                                        Artist
                                    </strong>
                                </th>
                                <th className="actions title">
                                    <strong>
                                        Actions
                                    </strong>
                                </th>
                            </tr>
                            </thead>

                            <tbody className="table-body">
                            {trackitems.map((ITrack, index) => (

                                <Draggable key={ITrack.id} draggableId={ITrack.id} index={index}>
                                    {(provided, snapshot) => (

                                        <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <td
                                                className="move"
                                                {...provided.dragHandleProps}
                                            >
                                                <i className="bi bi-grip-vertical"></i>
                                            </td>
                                            <td className="song">{ITrack.title}</td>
                                            <td className="artist">
                                                {Array.isArray(ITrack.artist) &&
                                                    ITrack.artist.join(", ")
                                                }
                                                {!Array.isArray(ITrack.artist) &&
                                                    ITrack.artist
                                                }
                                            </td>
                                            <td className="actions">
                                                <ButtonGroup>
                                                    {props.album &&
                                                        <EditTrackModal track={ITrack} album={props.album}/>
                                                    }
                                                    <Button
                                                        onClick={() => (props.type === 'playlist' ? setItemToRemove(String(index)) : setItemToDelete(ITrack.id))}><i
                                                        className={'bi bi-' + (props.type === 'playlist' ? 'archive' : 'trash')}
                                                        title={(props.type === 'playlist' ? 'Remove Track' : 'Delete Track')}/></Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            {/* Delete modal */}
            <ConfirmModal
                body={
                    (props.type === 'playlist' ? 'Removing a track means that it will no longer be visible on the playlist' : 'Deleting a track means that it will no longer be visible or playable')
                }
                show={!!itemToDelete || !!itemToRemove}
                onClose={() => (props.type === 'playlist' ? setItemToRemove('') : setItemToDelete(''))}
                onCancel={() => (props.type === 'playlist' ? setItemToRemove('') : setItemToDelete(''))}
                onConfirm={
                    (props.type === 'playlist' ? doRemove : doDelete)
                }
                cooldownSecs={5}
            />
        </>

    )
})

export default ListTable;