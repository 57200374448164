import {usePlaylists} from '../utils/api';
import PlaylistsTable from '@components/PlaylistsTable';
import CreatePlaylistModal from '@components/modals/CreatePlaylistModal';

const ManagePlaylists = () => {
  const {status} = usePlaylists();

  return <div className={'mt-4'}>
    <h3>Manage Playlists</h3>
    <p>
      Add, edit, and remove playlists from the database.
    </p>
    <CreatePlaylistModal />
    <div className={'mt-4'}>
      {status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : <PlaylistsTable />}
    </div>
  </div>
}

export default ManagePlaylists;