import {Button, Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';

interface IProps {
  show: boolean;
  onClose?: () => void;
  title?: string | JSX.Element;
  body?: string | JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelBtnText?: string;
  confirmBtnText?: string;
  cooldownSecs?: number; // to prevent clicking confirm until [x] seconds have passed
}

const ConfirmModal: React.FC<IProps> = (props) => {
  const {title, body, show, onClose, onCancel, onConfirm, cancelBtnText, confirmBtnText, cooldownSecs} = props;
  const [timer, setTimer] = useState(cooldownSecs || 0);

  useEffect(() => {
    let tmo: number | undefined;

    if (show && cooldownSecs) {
      tmo = window.setTimeout(() => {
        if (timer > 0) {
          setTimer((prev) => prev - 1);
        } else {
          // If the timer has finished, clear the timeout
          window.clearTimeout(tmo);
        }
      }, 1000);
    } else if (!show) {
      if (tmo) window.clearTimeout(tmo);
      setTimer(cooldownSecs || 0);
    }

    return () => {
      if (tmo) window.clearTimeout(tmo);
    }
  }, [show, timer, cooldownSecs]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title || 'Are you sure?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body || 'To continue, please click the Confirm button.'}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCancel}>
          {cancelBtnText || 'Cancel'}
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={timer > 0}>
          {(timer > 0) ? timer : (confirmBtnText || 'Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal;