import {Col, Form, Row} from 'react-bootstrap';
import FormBasicInput from '@components/forms/FormBasicInput';
import SpotifyLogo from '@assets/spotify.png';
import {Link} from 'react-router-dom';
import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {IAlbum} from '../../interfaces/PlayerModels';

export type AlbumFormData = Omit<IAlbum, 'tracks'>;

interface IProps {
    form: UseFormReturn<AlbumFormData>
}

/**
 * Form UI used on both NewAlbumModal and EditAlbumModal.
 */
const AlbumFormUI: React.FC<IProps> = (props) => {
    const {form} = props;
    const formValue = form.watch();

    return (
        <>
            <div className={'d-flex justify-content-center mb-4'}>
                <div className={'album-art-bg rounded'} style={{
                    width: '100px',
                    height: '100px',
                    backgroundImage: `url(${formValue.image}), url('https://via.placeholder.com/100')`
                }}/>
            </div>
            <Form>
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Title'}
                            name={'title'}
                            validation={{required: true}}
                            helpText={'The name of the album'}
                        />
                    </Col>
                    <Col>
                        {/* TODO get this actually working */}
                        <FormBasicInput
                            form={form}
                            label={'Artist'}
                            name={'artist'}
                            validation={{required: true}}
                            helpText={'Comma-separated list of artists'}
                        />
                    </Col>
                </Row>
                <FormBasicInput
                    form={form}
                    label={'Image'}
                    name={'image'}
                    validation={{required: false}}
                    helpText={'URL to the album art'}
                />
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={
                                <><img alt={'Spotify'} src={SpotifyLogo} width={20}/> Spotify URI</>
                            }
                            name={'platforms.spotify'}
                            validation={{required: false}}
                            helpText={
                                <Link target={'_blank'} to={'/'}>Learn how to obtain a Spotify URI</Link>
                            }
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AlbumFormUI;