import {Button, Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {IPlaylist} from '../../interfaces/PlayerModels';
import {useCreatePlaylist} from '../../utils/api';
import PlaylistFormUI, {PlaylistFormData} from '@components/forms/PlaylistFormUI';

const CreatePlaylistModal = () => {
    const mutation = useCreatePlaylist();
    const [show, setShow] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    // Form
    const form = useForm<PlaylistFormData>({
        mode: 'onChange'
    });
    const {handleSubmit, formState} = form;

    const savePlaylist: SubmitHandler<PlaylistFormData> = async (data) => {
        setSubmitError(false);

        try {
            // Clone the data and remove the ID from the request body.
            const albumData: Partial<IPlaylist> = Object.assign({}, data);

            // Fire off the mutation to save the playlist.
            await mutation.mutateAsync({data: albumData});

            // Successfully created the playlist, close the modal.
            setShow(false);

            window.location.reload();
        } catch (e) {
            // There was an error saving the playlist, show an error message.
            setSubmitError(true);
        }
    };

    useEffect(() => {
        if (!show) {
            // When this component is about to hide itself, reset the form.
            form.reset();
            setSubmitError(false);
        }
    }, [form, show]);

    return (
        <>
            <Button onClick={() => setShow(true)}><i className={'bi bi-plus-circle'} title={'Edit'}/> Add new
                playlist</Button>
            <Modal size={'lg'} show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create new playlist
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PlaylistFormUI form={form}/>
                </Modal.Body>
                <Modal.Footer>
                    {submitError &&
                        <p className={'text-danger'}>There was an error creating the playlist. Please try again.</p>}
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!formState.isValid} onClick={handleSubmit(savePlaylist)}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreatePlaylistModal;