import {Outlet} from 'react-router-dom';
import React from "react";
import Header from '@components/Header';
import {useMe} from './utils/api';
import {Container} from 'react-bootstrap';

const App = () => {
  const { data, status } = useMe();

  if (status === 'loading') {
    // Making the API call.
    return <Container fluid className={'mt-2'}>Please wait...</Container>
  } else if (status === 'error') {
    // Not logged in.
    // TODO redirect to login probably
    return <Container fluid className={'mt-2'}>Not logged in.</Container>
  } else if (data && !data.data.isAdmin) {
    // Not an admin, show an error message.
    return <Container fluid className={'mt-2'}>You are not authorised to access this page. Please <a href={'https://streambeats.app'}>return to streambeats.app</a>.</Container>
  }

  return (
    <div className='sb-app'>
      <Header />
      <Container>
        <Outlet />
      </Container>
    </div>
  )
}

export default App;
