import {Button, Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {ITrack} from '../../interfaces/PlayerModels';
import {useCreateTrack} from '../../utils/api';
import TrackFormUI, {TrackFormData} from "@components/forms/TrackFormUI";
import {useLoaderData} from "react-router-dom";
import {IPublicSingleAlbumResponse} from "../../interfaces/ApiResponses";


const CreateTrackModal = () => {
    const mutation = useCreateTrack();
    const [show, setShow] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    //Get current Album Information for Track
    const album = useLoaderData() as IPublicSingleAlbumResponse;
    const currentAlbum = {
        albumId: album.data.id
    }
    
    // Form
    const form = useForm<TrackFormData>({
        mode: 'onChange'
    });
    const {handleSubmit, formState} = form;

    const saveTrack: SubmitHandler<TrackFormData> = async (data) => {
        setSubmitError(false);

        try {
            // Clone the data and remove the ID from the request body.
            const trackData: Partial<ITrack> = Object.assign(currentAlbum, data);
            console.log(trackData);
            // Fire off the mutation to save the album.
            await mutation.mutateAsync({data: trackData});

            // Successfully created the album, close the modal.
            setShow(false);
        } catch (e) {
            // There was an error saving the album, show an error message.
            setSubmitError(true);
        }
    };

    useEffect(() => {
        if (!show) {
            // When this component is about to hide itself, reset the form.
            // form.reset();
            setSubmitError(false);
        }
    }, [form, show]);
    return (
        <>
            <Button onClick={() => setShow(true)}><i className={'bi bi-plus-circle'} title={'Edit'}/> Add new
                track</Button>
            <Modal size={'lg'} show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create new track
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TrackFormUI form={form}/>
                </Modal.Body>
                <Modal.Footer>
                    {submitError &&
                        <p className={'text-danger'}>There was an error creating the track. Please try again.</p>}
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!formState.isValid} onClick={handleSubmit(saveTrack)}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateTrackModal;