import {Col, Form, Row} from 'react-bootstrap';
import FormBasicInput from '@components/forms/FormBasicInput';
import SpotifyLogo from '@assets/spotify.png';
import {Link} from 'react-router-dom';
import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {ITrack} from '../../interfaces/PlayerModels';

export type TrackFormData = ITrack;

interface IProps {
    form: UseFormReturn<TrackFormData>;
    track?: ITrack;
}

/**
 * Form UI used on both NewAlbumModal, EditAlbumModal and EditTrackModal.
 */
const TrackFormUI: React.FC<IProps> = (props) => {
    const {form} = props;

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Title'}
                            name={'title'}
                            validation={{required: true}}
                            helpText={'The name of the track'}
                            value={props.track?.title}
                        />
                    </Col>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Artist'}
                            name={'artist'}
                            validation={{required: true}}
                            helpText={'Comma-separated list of artists'}
                            value={props.track?.artist.join(", ")}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Release Date'}
                            name={'releaseDate'}
                            validation={{required: true}}
                            value={props.track?.releaseDate}
                        />
                    </Col>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Track Number'}
                            name={'trackNumber'}
                            validation={{required: true}}
                            value={props.track?.trackNumber}
                            type='number'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'UPC'}
                            name={'upc'}
                            validation={{required: false}}
                            value={props.track?.upc}
                            type='number'
                        />
                    </Col>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'ISRC'}
                            name={'isrc'}
                            validation={{required: true}}
                            value={props.track?.isrc}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Genre'}
                            name={'genre'}
                            validation={{required: true}}
                            helpText={'Comma-separated list of genres'}
                            value={props.track?.genre.join(", ")}
                        />
                    </Col>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Producer'}
                            name={'producer'}
                            validation={{required: true}}
                            helpText={'Comma-separated list of producers'}
                            value={props.track?.producer.join(", ")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={'Composer'}
                            name={'composer'}
                            validation={{required: false}}
                            helpText={'Comma-separated list of composers'}
                            value={props.track?.composer.join(", ")}
                        />
                    </Col>
                    <Col>
                        <FormBasicInput
                            form={form}
                            label={
                                <><img alt={'Spotify'} src={SpotifyLogo} width={20}/> Spotify URI</>
                            }
                            name={'platforms.spotify'}
                            validation={{required: true}}
                            helpText={
                                <Link target={'_blank'} to={'/'}>Learn how to obtain a Spotify URI</Link>
                            }
                            value={props.track?.platforms.spotify}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TrackFormUI;