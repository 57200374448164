import {useLoaderData, useNavigate} from "react-router-dom";
import {IPublicSinglePlaylistResponse} from "../interfaces/ApiResponses";
import ListTable from "@components/TracksTable";
import AddTrackModal from "@components/modals/AddTrack";

const ManagePlaylistTracks = () => {

    const playlist = useLoaderData() as IPublicSinglePlaylistResponse;
    const navigate = useNavigate();

    function navPlaylist() {
        navigate('/p')
    }

    return <div className={'mt-4'}>
        <button onClick={navPlaylist} className="e-back"><i className="bi bi-chevron-left"></i> Back</button>

        <div className="e-top mb-4">
            <div className="image">
                <img src={playlist.data.image} alt=""/>
            </div>
            <div className="text">
                <h1>{playlist.data.name}</h1>
                <p>
                    {playlist.data.description}
                </p>
            </div>
        </div>
        <AddTrackModal/>
        <div className={'mt-4'}>
            <ListTable tracks={playlist.data.tracks} type="playlist"/>
        </div>
    </div>
}

export default ManagePlaylistTracks;