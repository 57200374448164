import {Col, Row} from 'react-bootstrap';
import {useStats} from '../utils/api';

const StatsPanel = () => {
  const {status, data} = useStats();

  return (
    <div className={'p-4 bg-white rounded'}>
      <h5 className={'extrabold'}>General Stats</h5>
      <Row>
        <Col>
          <ul>
            <li>
              Total tracks: <strong>{status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : data?.data.tracks}</strong>
            </li>
            <li>
              Total albums: <strong>{status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : data?.data.albums}</strong>
            </li>
            <li>
              Total playlists: <strong>{status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : data?.data.playlists}</strong>
            </li>
          </ul>
        </Col>
      </Row>
      <h5 className={'extrabold'}>User Stats</h5>
      <ul>
        <li>
          Total users: <strong>{status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : data?.data.users}</strong>
        </li>
        <li>
          ...using Spotify: <strong>{status === 'loading' ? <span className={'spinner-border spinner-border-sm text-primary'} /> : data?.data.usersSpotify}</strong>
        </li>
      </ul>
      <em>Stats updated on a regular basis.</em>
    </div>
  )
}

export default StatsPanel;