import {Button, Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {IPlaylist} from '../../interfaces/PlayerModels';
import {useEditPlaylist} from '../../utils/api';
import PlaylistFormUI, {PlaylistFormData} from '@components/forms/PlaylistFormUI';

interface IProps {
    playlist: IPlaylist;
}

const EditPlaylistModal: React.FC<IProps> = (props) => {
    const mutation = useEditPlaylist();
    const {playlist} = props;
    const [show, setShow] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    // Form
    const form = useForm<PlaylistFormData>({
        mode: 'onChange',
        defaultValues: playlist
    });
    const {handleSubmit, formState} = form;

    const savePlaylist: SubmitHandler<PlaylistFormData> = async (data) => {
        setSubmitError(false);

        try {
            // Clone the data and remove the ID from the request body.
            const playlistData: Partial<IPlaylist> = Object.assign({}, data);
            if (playlistData.hasOwnProperty('id')) delete playlistData.id;

            // Fire off the mutation to save the album.
            await mutation.mutateAsync({playlistId: data.id, data: playlistData});

            // Successfully saved the album, close the modal.
            setShow(false);
            window.location.reload();
        } catch (e) {
            // There was an error saving the album, show an error message.
            setSubmitError(true);
        }
    };

    useEffect(() => {
        if (!show) {
            // When this component is about to hide itself, reset the form.
            form.reset();
            setSubmitError(false);
        }
    }, [form, show]);

    useEffect(() => {
        // When the playlist prop changes, set the values in the form to the new value.
        form.reset(playlist);
    }, [form, playlist]);

    return (
        <>
            <Button onClick={() => setShow(true)}><i className={'bi bi-pencil'} title={'Edit'}/></Button>
            <Modal size={'lg'} show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit playlist <span className={'text-muted fs-6 ms-2'}>{playlist.id}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PlaylistFormUI form={form}/>
                </Modal.Body>
                <Modal.Footer>
                    {submitError &&
                        <p className={'text-danger'}>There was an error saving the album. Please try again.</p>}
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!formState.isValid} onClick={handleSubmit(savePlaylist)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditPlaylistModal;