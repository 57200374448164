import {Button, Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {ITrack} from '../../interfaces/PlayerModels';
import {useEditTrack} from '../../utils/api';
import TrackFormUI, {TrackFormData} from "@components/forms/TrackFormUI";

interface IProps {
    track: ITrack;
    album?: string;
}

const EditTrackModal: React.FC<IProps> = (props) => {
    const mutation = useEditTrack();
    const {track} = props;
    const [show, setShow] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    // Form
    const form = useForm<TrackFormData>({
        mode: 'onChange',
        defaultValues: track
    });
    const {handleSubmit, formState} = form;

    const saveTrack: SubmitHandler<TrackFormData> = async (data) => {
        setSubmitError(false);

        try {
            // Clone the data and remove the ID from the request body.
            const trackData: Partial<ITrack> = Object.assign({}, data);
            if (trackData.hasOwnProperty('id')) delete trackData.id;

            // Fire off the mutation to save the album.
            await mutation.mutateAsync({trackId: data.id, data: trackData});

            // Successfully saved the album, close the modal.
            setShow(false);

            window.location.reload();

        } catch (e) {
            // There was an error saving the album, show an error message.
            setSubmitError(true);
        }
    };

    useEffect(() => {
        if (!show) {
            // When this component is about to hide itself, reset the form.
            form.reset();
            setSubmitError(false);
        }
    }, [form, show]);

    useEffect(() => {
        // When the album prop changes, set the values in the form to the new value.
        form.reset(track);
    }, [form, track]);

    return (
        <>
            <Button onClick={() => setShow(true)}><i className={'bi bi-pencil'} title={'Edit'}/></Button>
            <Modal size={'lg'} show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit track <span className={'text-muted fs-6 ms-2'}>{track.id}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TrackFormUI form={form} track={track}/>
                </Modal.Body>
                <Modal.Footer>
                    {submitError &&
                        <p className={'text-danger'}>There was an error saving the track. Please try again.</p>
                    }
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!formState.isValid} onClick={handleSubmit(saveTrack)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditTrackModal;