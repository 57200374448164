import {Button, Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import {useAddPlaylistTrack, useAllTracks} from "../../utils/api";
import {observer} from "mobx-react-lite";
import {useLoaderData} from "react-router-dom";
import {IPublicSinglePlaylistResponse} from "../../interfaces/ApiResponses";

const AddTrackModal: React.FC = observer((props) => {
    const loadTracks = useAllTracks();
    const addTrack = useAddPlaylistTrack();
    const allTracks = loadTracks?.data?.data;
    const [selectedTrack, setSelectedTrack] = useState('');
    const playlist = useLoaderData() as IPublicSinglePlaylistResponse;

    const [show, setShow] = useState(false);

    const doAddTrack = async () => {
        const i = selectedTrack;
        setSelectedTrack(''); // Clear the item we want to add
        await addTrack.mutateAsync({playlistId: playlist.data.id, trackId: i, data: playlist.data});

        setShow(false);
        window.location.reload();
    };

    const cancelButton = () => {
        setShow(false);
        setSelectedTrack('')
    }

    return (
        <>
            <Button onClick={() => setShow(true)}><i className={'bi bi-pencil'} title={'Edit'}/> Add New Track</Button>
            <Modal size={'xl'} show={show} onHide={cancelButton}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Track
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="selectTracks">
                        <div className="trackTop">
                            <div>Song</div>
                            <div>Artist</div>
                            <div>Genre</div>
                        </div>
                        {allTracks?.map((ITrack, index) => (
                            <div className={`trackSelect ${selectedTrack === ITrack.id ? ' active' : ''}`}
                                 id={ITrack.id}
                                 onClick={() => selectedTrack === ITrack.id ? setSelectedTrack('') : setSelectedTrack(ITrack.id)}>
                                <div className="trackName">
                                    {ITrack.title}
                                </div>
                                <div className="trackArtist">
                                    {Array.isArray(ITrack.artist) &&
                                        ITrack.artist.join(", ")
                                    }
                                    {!Array.isArray(ITrack.artist) &&
                                        ITrack.artist
                                    }
                                </div>
                                <div className="trackGenre">
                                    {ITrack.genre.join(", ")}
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cancelButton}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={doAddTrack} disabled={selectedTrack === ''}>
                        Add Track
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
})

export default AddTrackModal;