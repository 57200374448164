import StatsPanel from '@components/StatsPanel';
import {Link} from 'react-router-dom';

const Home = () => {
  return <div className={'mt-4'}>
    <h3>Admin Dashboard</h3>
    <p>
      This dashboard is for managing the tracks, albums, and playlists that appear on <strong>streambeats.app</strong>.
      Only users with admin privileges can access this dashboard. If a new user requires access to this dashboard, please contact the Aitum team.
    </p>
    <StatsPanel />
    <div className={'mt-4'}>
      <h4>Help and guidance</h4>
      <p>
        <strong>How do I add a new track?</strong><br />
        All tracks are part of an album. To add a new track, click on an album on the <Link to={'a'}>Manage Albums</Link> page.
      </p>
      <p>
        <strong>How do I find a Spotify URI?</strong><br />
        Right-click a track on the Spotify app to open a menu. Hover over "Share", then hold Alt (Windows)/Option (Mac) to show the hidden "Copy Spotify URI" button.
      </p>
      <p>
        <strong>Can tracks be part of more than one album?</strong><br />
        No, each track is unique to an album. If the same recording is used in multiple albums, add a track for each album.
      </p>
    </div>
  </div>
}

export default Home;