import {useLoaderData, useNavigate} from "react-router-dom";
import {IPublicSingleAlbumResponse} from "../interfaces/ApiResponses";
import React, {useMemo} from "react";
import ListTable from "@components/TracksTable";
import CreateTrackModal from "@components/modals/CreateTrackModal";


const ManageAlbumTracks = () => {

    const album = useLoaderData() as IPublicSingleAlbumResponse;
    const navigate = useNavigate();

    const dateYear = useMemo(() => {
        // If there is no release date, return an empty string.
        if (!album.data.releaseDate) return '';
        // Otherwise, get the year from the Date object.
        const date = new Date(album.data.releaseDate);
        return date.getFullYear();
    }, [album.data.releaseDate]);

    function navAlbums() {
        navigate('/a')
    }


    let artist;
    if (!Array.isArray(album.data.artist)) {
        artist = <>{album.data.artist}</>
    } else {
        artist = <> {album.data.artist.join(', ')}</>
    }

    return <div className={'mt-4'}>
        <button onClick={navAlbums} className="e-back"><i className="bi bi-chevron-left"></i> Back</button>

        <div className="e-top mb-4">
            <div className="image">
                <img src={album.data.image} alt=""/>
            </div>
            <div className="text">
                <h1>{album.data.title}</h1>
                <p>
                    {artist}
                    <br/>
                    {dateYear}
                </p>
            </div>
        </div>
        <CreateTrackModal/>
        <div className={'mt-4'}>
            <ListTable tracks={album.data.tracks} album={album.data.id} type="album"/>
        </div>
    </div>
}

export default ManageAlbumTracks;