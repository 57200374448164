import {useDeletePlaylist, usePlaylists} from '../utils/api';
import React, {useMemo, useState} from 'react';
import {Column, useTable} from 'react-table';
import {IPlaylist} from '../interfaces/PlayerModels';
import {Button, ButtonGroup} from 'react-bootstrap';
import ConfirmModal from '@components/modals/ConfirmModal';
import EditPlaylistModal from '@components/modals/EditPlaylistModal';
import {useNavigate} from 'react-router-dom';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

type TableData = Omit<IPlaylist, 'tracks'>;

const PlaylistsTable = () => {
    const mutation = useDeletePlaylist();
    const {data} = usePlaylists();
    const [itemToDelete, setItemToDelete] = useState('');
    const navigate = useNavigate();

    // Called if the user confirms the deletion
    const doDelete = async () => {
        const i = itemToDelete;
        setItemToDelete(''); // Clear the item we want to delete, so we can close the modal
        await mutation.mutateAsync({playlistId: i});

        window.location.reload();
    };

    // Memoize this so that it isn't re-created on every re-render.
    const tableData = useMemo<TableData[]>(() => {
        if (!data || !data.data) return [];
        return data.data.map((p) => {
            return {
                id: p.id,
                name: p.name,
                image: p.image,
                description: p.description
            }
        })
    }, [data]);

    // Define our columns. Memoize this so that it isn't re-created on every re-render.
    const tableCols = useMemo<Column<TableData>[]>(() => [
        {
            Header: 'Image',
            accessor: 'image',
            Cell: ({value}: { value?: string }) => {
                // If there is no image, show nothing.
                if (!value) return <></>;
                // If there is an image URL, render it.
                return <img alt={'Playlist art'} src={value} width={75}/>
            }
        },
        {
            Header: 'Name',
            accessor: 'name',
            className: 'name'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Actions',
            Cell: ({cell}: { cell: any }) => {
                const origData = cell.row.original as IPlaylist;

                function navToPlaylist() {
                    navigate('/p/' + origData.id)
                }

                return <>
                    <ButtonGroup>
                        <EditPlaylistModal playlist={origData as IPlaylist}/>
                        <Button onClick={navToPlaylist}><i className={'bi bi-file-earmark-music'}
                                                           title={'Manage tracks'}/></Button>
                        <Button onClick={() => setItemToDelete(origData.id)}><i className={'bi bi-trash'}
                                                                                title={'Delete'}/></Button>
                    </ButtonGroup>
                </>
            }
        }
    ], [navigate]);

    // Init the table
    const table = useTable({
        columns: tableCols,
        data: tableData,
    })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
    } = table;

    // Drag / Drop Setup
    const [rows, setRows] = useState(table.rows);

    //Item Styles for when dragging
    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        display: isDragging ? "table" : "table-row",
        boxShadow: isDragging ? "0 0 5px rgba(0,0,0,0.2)" : "none",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    // Reordering the result
    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Update view on drag end
    const onDragEnd = (result: any) => {
        console.log(rows);
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index
        );

        setRows(items);
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided) => (
                        <table
                            className={'table table-striped table-playlist'}
                            ref={provided.innerRef}
                            {...getTableProps()}>
                            <thead>
                            {
                                headerGroups.map((group) => (
                                    <tr {...group.getHeaderGroupProps()}>
                                        <th className='move'></th>
                                        {
                                            group.headers.map((col) => (
                                                <th {...col.getHeaderProps()}>
                                                    {col.render('Header')}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {
                                rows.map((r, index) => {
                                    prepareRow(r);
                                    return (
                                        <Draggable key={r.id} draggableId={r.id} index={index}>
                                            {(provided, snapshot) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...r.getRowProps()}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <td
                                                        className='move'
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <i className="bi bi-grip-vertical"></i>
                                                    </td>
                                                    {r.cells.map((c) => {
                                                        return (
                                                            <td {...c.getCellProps()}>
                                                                {c.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )}
                                        </Draggable>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            {/* Delete modal */}
            <ConfirmModal
                body={'Deleting a playlist means that it will no longer be visible or playable. You will not be able to recover it and will have to create a new one.'}
                show={!!itemToDelete}
                onClose={() => setItemToDelete('')}
                onCancel={() => setItemToDelete('')}
                onConfirm={doDelete}
                cooldownSecs={5}
            />
        </>
    )
}

export default PlaylistsTable;