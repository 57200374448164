import {Col, Form, Row} from 'react-bootstrap';
import FormBasicInput from '@components/forms/FormBasicInput';
import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {IPlaylist} from '../../interfaces/PlayerModels';

export type PlaylistFormData = Omit<IPlaylist, 'tracks'>;

interface IProps {
  form: UseFormReturn<PlaylistFormData>
}

/**
 * Form UI used on both NewPlaylistModal and EditPlaylistModal.
 */
const PlaylistFormUI: React.FC<IProps> = (props) => {
  const {form} = props;
  const formValue = form.watch();

  return (
    <>
      <div className={'d-flex justify-content-center mb-4'}>
        <div className={'album-art-bg rounded'} style={{width: '100px', height: '100px', backgroundImage: `url(${formValue.image}), url('https://via.placeholder.com/100')`}} />
      </div>
      <Form>
        <Row>
          <Col>
            <FormBasicInput
              form={form}
              label={'Name'}
              name={'name'}
              validation={{required: true}}
              helpText={'The name of the playlist'}
            />
          </Col>
          <Col>
            <FormBasicInput
              form={form}
              label={'Image'}
              name={'image'}
              validation={{required: false}}
              helpText={'URL to the playlist album art'}
            />
          </Col>
        </Row>
        <FormBasicInput
          form={form}
          label={'Description'}
          name={'description'}
          validation={{required: false}}
          helpText={'The description of the playlist'}
        />
      </Form>
    </>
  )
}

export default PlaylistFormUI;