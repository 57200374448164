import React from "react";
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';

const Header = () => {
  return (
    <div>
      <Navbar variant={'dark'} bg="primary"  expand="lg">
        <Container>
          <Navbar.Brand as={Link} to={'/'} className={'extrabold'} href="#home">
            <span>
              <i className={'bi bi-house'} />
            </span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav>
              <Nav.Link as={NavLink} to={'/p'} className={'extrabold text-white text-lowercase'}>Manage Playlists</Nav.Link>
              <Nav.Link as={NavLink} to={'/a'} className={'extrabold text-white text-lowercase'}>Manage Albums</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header;