import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import ReactDOM from 'react-dom/client';
import '@styles/index.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import ManagePlaylists from '@pages/ManagePlaylists';
import ManageAlbums from '@pages/ManageAlbums';
import Home from '@pages/Home';
import Api from "./utils/api";
import ManageAlbumTracks from "@pages/ManageAlbumTracks";
import ManagePlaylistTracks from "@pages/ManagePlaylistTracks";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'p',
        element: <ManagePlaylists />
      },
      {
        path: 'a',
        element: <ManageAlbums />
      },
      {
        path: 'a/:albumId',
        loader: async ({ params }) => {
          return Api.getAlbumId(params.albumId)
        },
        element: <ManageAlbumTracks />,
      },
      {
        path: 'p/:playlistId',
        loader: async ({ params }) => {
          return Api.getPlaylistId(params.playlistId)
        },
        element: <ManagePlaylistTracks />,
      }
    ]
  }
])

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
);
