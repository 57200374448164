import {Form} from 'react-bootstrap';
import {
    Controller,
    FieldPath,
    FieldValues,
    RegisterOptions,
    UseFormReturn
} from 'react-hook-form';
import React from 'react';

export interface IProps<Tform extends FieldValues> {
    form: UseFormReturn<Tform>
    name: FieldPath<Tform>;
    validation: RegisterOptions;
    label?: string | JSX.Element;
    value?: string | number;
    helpText?: string | JSX.Element;
    readonly?: boolean;
    type?: string;
}

/**
 * Generic component for building a form input.
 */
const FormBasicInput = <T extends FieldValues>(props: IProps<T>) => {

    return (
        <Controller
            control={props.form.control}
            name={props.name}
            rules={props.validation}

            render={({field, fieldState}) => (
                <Form.Group className="mb-3" controlId={props.name}>
                    {props.label &&
                        <Form.Label classNamse={'user-select-none'}>{props.label}</Form.Label>}
                    <Form.Control
                        {...field}
                        onChange={(evt) => {
                            field.onChange(evt);
                        }}
                        isInvalid={!!fieldState.error}
                        readOnly={props.readonly}
                        type={props.type}
                        contentEditable={true}
                        defaultValue={props.value}
                    />
                    {props.helpText &&
                        <Form.Text className="text-muted user-select-none">
                            {props.helpText}
                        </Form.Text>
                    }
                    <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                    </Form.Control.Feedback>

                </Form.Group>
            )}
        />
    )
}

export default FormBasicInput;